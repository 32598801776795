import { FormProductOptionValue } from '@common/models/Option';
import { PRODUCT_CUSTOM_FIELD_TYPE } from '@common/models/Product';
import { CartProductType } from '@common/store/cart/slice';
import { addProductToCart, removeProductFromCart, subtractProductFromCart } from '@common/store/cart/slice';
import { WishListSliceType } from '@common/store/wishlist/slice';
import Rupee from '@components/Rupee';
import Stepper from '@components/Stepper';
import Remove from '@images/Remove.svg';
import Rupees from '@instamojo/rupee';
import React, { useEffect, useState } from 'react';

import { isMobile } from '../../../../common/utils';
interface CartItemProps {
  index?: number;
  product: CartProductType;
  addProductToCart: typeof addProductToCart;
  subtractProductFromCart: typeof subtractProductFromCart;
  removeProductFromCart: typeof removeProductFromCart;
  cartProductQuantity: number;
  cartHasOptionProduct: boolean;
  cartHasVariant: boolean;
  alterCoupon?: () => void;
  removeProductFromBundle: ({ product: CartProductType, isRemoveProduct: boolean }) => void;
  addUserWishList?: (product: CartProductType) => void;
  removeUserWishList?: (product: CartProductType) => void;
  wishlist: WishListSliceType;
  taxIncludedInventory: boolean;
}

const CartItem: React.FC<CartItemProps> = ({
  index,
  product,
  addProductToCart,
  subtractProductFromCart,
  removeProductFromCart,
  cartProductQuantity,
  cartHasVariant,
  alterCoupon,
  removeProductFromBundle,
  addUserWishList,
  // removeUserWishList,
  wishlist,
  taxIncludedInventory,
}) => {
  const { wishListProducts } = wishlist;
  const [isProductInWishlist, setisProductInWishlist] = useState(false);

  useEffect(() => {
    getWishList(product);
  }, [product, wishListProducts]);

  const getWishList = (product) => {
    const isFound = wishListProducts?.results?.find((wishListProduct) => {
      return wishListProduct?.id === product?.id;
    });
    if (isFound) setisProductInWishlist(true);
    else return setisProductInWishlist(false);
  };

  const removeProduct = () => {
    product.bundleId ? removeProductFromBundle({ product, isRemoveProduct: true }) : removeProductFromCart({ product });
  };

  const onWishListClickHandler = () => {
    if (isProductInWishlist) {
      removeProduct();
    } else {
      addUserWishList(product);
      removeProduct();
    }
    setisProductInWishlist(!isProductInWishlist);
  };

  const optionValues = (option) => {
    return (
      <div className=" md:text-14 text-16 flex font-semibold md:pl-6 md:pr-15 justify-center items-center md:rounded md:w-full">
        {option?.field_type === PRODUCT_CUSTOM_FIELD_TYPE.TEXT ||
        option?.field_type === PRODUCT_CUSTOM_FIELD_TYPE.DATE ||
        option?.field_type === PRODUCT_CUSTOM_FIELD_TYPE.DATETIME ? (
          <div>{option?.values[0]}</div>
        ) : option?.field_type === PRODUCT_CUSTOM_FIELD_TYPE.FILE ? (
          <div>
            <a
              href={option.public_urls[option.values[0]] as string}
              target="_blank"
              rel="noreferrer"
              download={option.public_urls[option.values[0]]}
              className="text-textColor"
            >
              File Uploaded
            </a>
          </div>
        ) : option?.field_type === PRODUCT_CUSTOM_FIELD_TYPE.RADIO ? (
          <div>{(option?.values as FormProductOptionValue).join(', ')}</div>
        ) : option?.field_type === PRODUCT_CUSTOM_FIELD_TYPE.CHECKBOX ? (
          <div>
            <div>{(option.values as FormProductOptionValue[]).map((v) => v).join('|')}</div>
          </div>
        ) : option.field_type === PRODUCT_CUSTOM_FIELD_TYPE.MULTI_FILE ? (
          <div>
            {(option?.values as FormProductOptionValue[]).map((v, index) => {
              return (
                <div key={index}>
                  <a
                    href={option?.public_urls[v] as string}
                    target="_blank"
                    rel="noreferrer"
                    download={option?.public_urls[v]}
                    className="text-textColor"
                  >
                    File Uploaded {index + 1}
                  </a>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  };
  if (isMobile())
    return (
      <>
        <div key={product.id} className="w-full py-22 rounded-lg px-20 mb-20 border-slideBorder border">
          <div className="flex">
            <div className="flex flex-wrap md:flex-nowrap">
              <div>
                <div className="mr-14 h-80 w-80 md:rounded-3  md:mr-20 md:h-125 md:w-125 rounded overflow-hidden">
                  <img
                    className="object-contain h-80 w-80 md:rounded-3 border border-slideBorder md:h-125 md:w-125"
                    src={product.images[0]}
                    alt={product.title}
                  />
                </div>
              </div>
            </div>
            <div className="flex w-full flex-col relative">
              <div className="md:flex md:justify-between">
                <div
                  className="mb-14 text-14 md:mb-16 font-normal md:mr-16 text-black"
                  font-customisation="section-heading"
                  color-customisation="page-heading"
                >
                  {product.title}
                </div>
                <div
                  className="flex flex-col mb-20 md:mb-0 flex-wrap justify-start md:justify-end text-left md:text-right"
                  font-customisation="para-text"
                >
                  <div className="flex text-left justify-start md:justify-end items-end ">
                    <div className="text-14 text-textColor font-normal  flex" color-customisation="page-body-text">
                      <Rupee value={product.cartQuantity * product.finalPrice} defaultClass={true} />
                    </div>
                    {product.price > product.finalPrice && (
                      <div className="text-disabled ml-4 line-through text-14 font-normal">
                        <Rupee value={product.cartQuantity * product.price} defaultClass={true} />
                      </div>
                    )}
                    <div className="text-14 font-normal ml-4 text-disabled">
                      {taxIncludedInventory ? 'Incl. of taxes' : 'Excl. of taxes'}
                    </div>
                  </div>
                </div>
              </div>
              {cartHasVariant ? (
                <div className="flex-wrap hidden md:flex">
                  {product?.optionValues?.map((item, index) => {
                    return (
                      <div
                        className="text-disabled flex items-center font-normal text-14 leading-24 mr-15 border-r border-cartBorder last:border-0"
                        key={item}
                        font-customisation="para-text"
                        color-customisation="page-body-text"
                      >
                        <p className="mr-2 font-normal">{product?.optionNames[index]}</p>
                        <p className="font-normal mr-4 ">:</p>
                        <p className="font-normal flex mr-15  ">{item}</p>
                      </div>
                    );
                  })}
                </div>
              ) : null}
              {cartHasVariant ? (
                <div className="flex-wrap md:hidden">
                  {product?.optionValues?.map((item, index) => {
                    return (
                      <div
                        className="text-disabled flex items-center font-normal text-14 leading-24 mr-15"
                        key={item}
                        font-customisation="para-text"
                        color-customisation="page-body-text"
                      >
                        <p className="mr-4 font-normal">{product?.optionNames[index]} </p>
                        <p className="font-normal mr-4 ">:</p>
                        <p className="font-normal ">{item}</p>
                      </div>
                    );
                  })}
                </div>
              ) : null}
              <div className="flex-wrap md:flex gap-14">
                {product?.formProductOptions?.map((option) => {
                  return (
                    <div
                      key={option.option.id}
                      className="w-fit relative mt-16 text-14  text-disabled border-0 md:border-r border-cartBorder last:border-0"
                    >
                      <div
                        className="hidden md:flex items-center"
                        font-customisation="para-text"
                        color-customisation="page-body-text"
                      >
                        <p className="font-normal text-14 leading-24 hidden md:flex ">{option.option.name} :</p>
                        <p className="hidden md:block">{optionValues(option)}</p>
                      </div>
                      <div className="flex justify-start">
                        <span
                          className="flex flex-wrap text-14 leading-24 font-normal md:mt-6 md:hidden"
                          font-customisation="para-text"
                          color-customisation="page-body-text"
                        >
                          {option.option.name}
                          <p className="font-normal ml-6 mr-6 md:hidden">:</p>

                          {optionValues(option)}
                        </span>
                      </div>
                    </div>
                  );
                })}

                {product?.formCustomFields?.map((option) => {
                  return (
                    <div
                      key={option?.id}
                      className="w-fit relative mt-16 text-14  text-disabled border-0 md:border-r border-cartBorder last:border-0"
                    >
                      <div
                        className="hidden md:flex items-center"
                        font-customisation="para-text"
                        color-customisation="page-body-text"
                      >
                        <p className="font-normal text-14 leading-24 hidden md:flex ">{option?.title} :</p>
                        <p className="hidden md:block">{optionValues(option)}</p>
                      </div>
                      <div className="flex justify-start">
                        <span
                          className="flex flex-wrap text-14 leading-24 font-normal md:mt-6 md:hidden"
                          font-customisation="para-text"
                          color-customisation="page-body-text"
                        >
                          {option?.title}
                          <p className="font-normal ml-6 mr-6 md:hidden">:</p>

                          {optionValues(option)}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="mt-18 flex flex-1 items-end">
                <div className="flex items-center" font-customisation="para-text">
                  <div className="text-textColor text-14 font-normal mr-12" color-customisation="page-body-text">
                    Qty
                  </div>
                  <div>
                    {!product.bundleId ? (
                      <Stepper
                        index={index}
                        isSmallerVariant
                        product={product}
                        subtractProductFromCart={subtractProductFromCart}
                        addProductToCart={addProductToCart}
                        quantity={product.cartQuantity}
                        cartProductQuantity={cartProductQuantity}
                        alterCoupon={alterCoupon}
                        className="py-6 h-30 w-124 min-w-124 border border-searchBorder rounded-3"
                      />
                    ) : (
                      <div className="text-black_variant text-14 md:text-16">{product.cartQuantity}</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="md:hidden">
            <div className="w-full"></div>
            <div className="w-full justify-end flex">
              <div
                onClick={(e) => {
                  e.preventDefault();
                  onWishListClickHandler();
                }}
                className="cursor-pointer hidden pr-10 mr-10 border-r border-cartBorder  justify-end mt-24 w-fit font-bold text-16 leading-16 text-textColor"
                font-customisation="para-text"
                color-customisation="page-body-text"
              >
                Move To Wishlist
              </div>
              <div
                onClick={() => {
                  removeProduct();
                  alterCoupon();
                }}
                className="cursor-pointer justify-end items-center gap-2 flex mt-12 border border-subtext p-4 rounded-3 w-fit font-normal text-12 leading-14 text-subtext"
                font-customisation="para-text"
                color-customisation="page-body-text"
                id={'cart-item-delete-' + (index + 1)}
              >
                <img src={Remove} alt="remove" className="w-10 h-10" />
                Remove
              </div>
            </div>
          </div>
        </div>
        {/* Table for web view */}
      </>
    );
  else {
    return (
      <tr key={product.id} className="w-full py-22 rounded-lg px-20 mb-20">
        <td className="">
          <div className="flex p-16">
            <img className="object-contain h-40 w-70 " src={product.images[0]} alt={product.title} />
            <div className="flex flex-col pl-16">
              <div
                className="mb-14 text-14 md:mb-16 font-normal md:mr-16 text-black"
                font-customisation="section-heading"
                color-customisation="page-heading"
              >
                {product.title}
              </div>
              <div>
                {cartHasVariant ? (
                  <div className="flex-wrap hidden md:flex">
                    {product?.optionValues?.map((item, index) => {
                      return (
                        <div
                          className="text-disabled flex items-center font-semibold text-14 leading-24 mr-15 border-r border-cartBorder last:border-0"
                          key={item}
                          font-customisation="para-text"
                          color-customisation="page-body-text"
                        >
                          <p className="mr-2 font-semibold">{product?.optionNames[index]}</p>
                          <p className="font-semibold mr-4 ">:</p>
                          <p className="font-semibold flex mr-15  ">{item}</p>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
              <div className="flex-wrap md:flex gap-14">
                {product?.formProductOptions?.map((option) => {
                  return (
                    <div
                      key={option.option.id}
                      className="w-fit relative mt-16 text-16  text-disabled border-0 md:border-r border-cartBorder last:border-0"
                    >
                      <div
                        className="hidden md:flex items-center"
                        font-customisation="para-text"
                        color-customisation="page-body-text"
                      >
                        <p className="font-semibold text-14 leading-24 hidden md:flex ">{option.option.name} :</p>
                        <p className="hidden md:block">{optionValues(option)}</p>
                      </div>
                      <div className="flex justify-start">
                        <span
                          className="flex flex-wrap text-16 leading-24 font-semibold md:mt-6 md:hidden"
                          font-customisation="para-text"
                          color-customisation="page-body-text"
                        >
                          {option.option.name}
                          <p className="font-semibold ml-6 mr-6 md:hidden">:</p>

                          {optionValues(option)}
                        </span>
                      </div>
                    </div>
                  );
                })}
                {product?.formCustomFields?.map((option) => {
                  return (
                    <div
                      key={option?.id}
                      className="w-fit relative mt-16 text-16  text-disabled border-0 md:border-r border-cartBorder last:border-0"
                    >
                      <div
                        className="hidden md:flex items-center"
                        font-customisation="para-text"
                        color-customisation="page-body-text"
                      >
                        <p className="font-semibold text-14 leading-24 hidden md:flex ">{option?.title} :</p>
                        <p className="hidden md:block">{optionValues(option)}</p>
                      </div>
                      <div className="flex justify-start">
                        <span
                          className="flex flex-wrap text-16 leading-24 font-semibold md:mt-6 md:hidden"
                          font-customisation="para-text"
                          color-customisation="page-body-text"
                        >
                          {option?.title}
                          <p className="font-semibold ml-6 mr-6 md:hidden">:</p>

                          {optionValues(option)}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  onWishListClickHandler();
                }}
                className="cursor-pointer pr-10 mr-10 hidden border-r border-cartBorder  justify-end mt-12  w-fit font-semibold underline text-14 leading-14 text-textColor"
                font-customisation="para-text"
                color-customisation="page-body-text"
              >
                Move To Wishlist
              </div>
              <div
                onClick={() => {
                  removeProduct();
                  alterCoupon();
                }}
                className="cursor-pointer justify-end flex mt-12  w-fit font-normal text-12 leading-14 text-subtext"
                font-customisation="para-text"
                color-customisation="page-body-text"
                id={'cart-item-delete-' + (index + 1)}
              >
                <img src={Remove} alt="remove" className="w-10 h-10" />
                Remove
              </div>
            </div>
          </div>
        </td>
        <td className="" font-customisation="para-text">
          <div>
            {!product.bundleId ? (
              <Stepper
                index={index}
                isSmallerVariant
                product={product}
                subtractProductFromCart={subtractProductFromCart}
                addProductToCart={addProductToCart}
                quantity={product.cartQuantity}
                cartProductQuantity={cartProductQuantity}
                alterCoupon={alterCoupon}
                className="py-6 h-36 w-134 min-w-134 border border-cartTable-border rounded-3"
              />
            ) : (
              <div className="text-black_variant text-14 md:text-16">{product.cartQuantity}</div>
            )}
          </div>
        </td>
        <td className="" font-customisation="para-text">
          <div className="px-16 text-14 text-prodTitleTxt">
            <Rupees className="" />
            {product.finalPrice}
          </div>
        </td>
        <td>
          <div className="flex flex-col px-16 flex-wrap justify-start text-left" font-customisation="para-text">
            <div className="flex text-left justify-start items-end ">
              <div className="text-14 text-prodTitleTxt flex" color-customisation="page-body-text">
                <Rupee value={product.cartQuantity * product.finalPrice} defaultClass={true} />
              </div>
              <div className="hidden">
                {product.price > product.finalPrice && (
                  <div className="text-disabled leading-22 line-through text-14 ml-10 md:ml-4 font-normal h-20">
                    <Rupee value={product.cartQuantity * product.price} defaultClass={true} />
                  </div>
                )}
                <div className="text-12 font-semibold text-disabled">
                  {taxIncludedInventory ? 'Incl. of taxes' : 'Excl. of taxes'}
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    );
  }
};

export default CartItem;

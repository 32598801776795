import CartProduct from '@common/models/CartProduct';

export const getProductIds = (products: Array<CartProduct>): string => {
  const productId = [];
  for (const productHash in products) {
    const product = products[productHash];
    for (const productKey in product) {
      if (productKey == 'id') {
        productId.push(product[productKey]);
      }
    }
  }
  return productId.toString();
};

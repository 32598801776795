import { computeDiscountForLinks } from '@common/api';
import useForm, { FormReturnType, isEmpty } from '@common/hooks/useForm';
import { getFormCustomField } from '@common/models/CustomField';
import { getFormProductOptions } from '@common/models/Option';
import Product, {
  PRODUCT_CUSTOM_FIELD_TYPE,
  // PRODUCT_OPTIONS_TYPE,
  PRODUCT_TYPE,
  ProductOverridePropsType,
  SavingsType,
} from '@common/models/Product';
import { STORE_TYPE } from '@common/models/StoreInfo';
import Variant from '@common/models/Variant';
import { RootState } from '@common/store';
import { addProductToCart, AddToCartParams, CartProductType, CartSliceType } from '@common/store/cart/slice';
import { StoreInfoSliceType } from '@common/store/storeInfo/slice';
import { addToWishlist, getProdWishlist, removeFromWishlist } from '@common/store/wishList/slice';
import { computeRedirectUrl } from '@common/utils/computation';
import { COOKIE_KEYS, CUSTOM_FIELDS_MAX_FILE_SIZE } from '@common/utils/constants';
import { formatTimestamp2 } from '@common/utils/date';
import { getBuyerJWTToken, setCookie } from '@common/utils/token';
import { validateFileSize, validateRequired } from '@common/utils/validations';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { useVariant, UseVariantReturnType } from '../SelectVariants';

export interface ProductAddToCartHOCRenderArgs {
  finalProduct: Product | Variant;
  showTicketsLeft: boolean;
  eventStartDate: string | null;
  eventStartTime: string | null;
  eventEndDate: string | null;
  eventEndTime: string | null;
  editedPrice: string;
  setEditedPrice: (s: string) => void;
  editedPriceError: string;
  form: FormReturnType;
  customFieldForm: FormReturnType;
  variant: UseVariantReturnType;
  // eslint-disable-next-line
  optionsFormRef: React.MutableRefObject<any>;
  // eslint-disable-next-line
  customFieldFormRef: React.MutableRefObject<any>;
  addToCart: (params: AddToCartParams) => void;
  minQuantityGreaterThanOne: boolean;
  isBuyNowButtonDisabled: boolean;
  isAddToCartButtonDisabled: boolean;
  showAddToCartButton: boolean;
  showAddToCartButtonAlt: boolean;
  showStepper: boolean;
  cart: CartSliceType;
  storeInfo: StoreInfoSliceType;
  finalPrice: number;
  discountCode: string;
  savings: SavingsType;
  wishlist: WishlistType;
  addUserWishList: () => void;
  removeUserWishList: () => void;
  onWishListClickHandler: () => void;
}
interface DisableSuccessToastsArgs {
  product: Product;
  finalProduct: Variant;
  isBuyNowFlow: boolean;
}
interface WishlistType {
  wishListProducts: Array<string>;
  isProductInWishList: boolean;
}
interface ProductAddToCartHOCProps {
  addProductToCart: typeof addProductToCart;
  addToWishlist: typeof addToWishlist;
  removeFromWishlist: typeof removeFromWishlist;
  storeInfo: StoreInfoSliceType;
  wishlist: WishlistType;
  product: Product;
  render: (args: ProductAddToCartHOCRenderArgs) => React.ReactNode;
  cart: CartSliceType;
  variantSetToMinQuantity?: boolean;
  noInitialVariant?: boolean;
  noInitialQuantity?: boolean;
  disableSuccessToasts?: (args: DisableSuccessToastsArgs) => boolean;
  getProdWishlist: typeof getProdWishlist;
}

const ProductAddToCartHOC: React.FC<ProductAddToCartHOCProps> = ({
  render,
  product,
  storeInfo,
  addProductToCart,
  cart,
  variantSetToMinQuantity,
  noInitialVariant,
  noInitialQuantity,
  addToWishlist,
  wishlist,
  getProdWishlist,
  removeFromWishlist,
  disableSuccessToasts = ({ product, finalProduct }: DisableSuccessToastsArgs) =>
    !(product.hasVariants || product.hasOptions || product.hasCustomField || finalProduct.payWhatYouWant),
}) => {
  const variant = useVariant({
    product,
    cart,
    variantSetToMinQuantity,
    noInitialVariant,
    noInitialQuantity,
  });
  const { setSelectedOptions, quantity, selectedVariant, selectedVariantCartQuantity } = variant;
  const isStoreInfoFetching = storeInfo.isFetching;
  const storeType = storeInfo.storeInfo?.storeType;
  // const isPaymentsDisabled = !storeInfo.storeInfo?.paymentsAllowed;
  const finalProduct = selectedVariant ?? product;
  const buyNowItem = getBuyNowItem({ selectedVariant, product });
  const optionsFormRef = useRef(null);
  const customFieldFormRef = useRef(null);
  const [finalPrice, setFinalPrice] = useState(finalProduct.finalPrice);
  const [savings, setSavings] = useState(finalProduct.savings);
  const [discountCode, setDiscountCode] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const [editedPrice, setEditedPrice] = useState(finalPrice.toString());
  const [editedPriceError, setEditedPriceError] = useState('');
  const isBuyNowButtonDisabled =
    isStoreInfoFetching ||
    // (isPaymentsDisabled && storeType === STORE_TYPE.SHOPPING_STORE) ||
    product.isExpired ||
    !buyNowItem ||
    storeType === STORE_TYPE.LANDING ||
    (product.hasVariants && !selectedVariant) ||
    quantity === -1;

  const isAddToCartButtonDisabled =
    isStoreInfoFetching ||
    // (isPaymentsDisabled && storeType === STORE_TYPE.SHOPPING_STORE) ||
    product.isExpired ||
    (product.hasVariants && !selectedVariant) ||
    quantity === -1;

  const showAddToCartButtonAlt = storeType != STORE_TYPE.DISABLED_CART && !discountCode;
  const showAddToCartButton =
    showAddToCartButtonAlt &&
    (product.hasVariants ||
      product.hasOptions ||
      product.hasCustomField ||
      finalProduct.payWhatYouWant ||
      !selectedVariantCartQuantity);
  const showStepper =
    !product.hasVariants &&
    !product.hasOptions &&
    !product.hasCustomField &&
    !finalProduct.payWhatYouWant &&
    !!selectedVariantCartQuantity;

  const showTicketsLeft =
    !isBuyNowButtonDisabled && !finalProduct.unlimitedQuantity && finalProduct.type === PRODUCT_TYPE.EVENT;

  let eventStartDate, eventStartTime, eventEndDate, eventEndTime;

  const { isProductInWishList } = wishlist;
  const redirectUrl = computeRedirectUrl();

  if (finalProduct.type === PRODUCT_TYPE.EVENT) {
    [eventStartDate, eventStartTime] = formatTimestamp2(finalProduct.event?.startDate);
    [eventEndDate, eventEndTime] = formatTimestamp2(finalProduct.event?.endDate);
  }
  const minQuantityGreaterThanOne = finalProduct.minQuantity > 1;

  useEffect(() => {
    setEditedPrice(finalPrice.toString());
  }, [finalPrice]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const discountCode = queryParams.get('discount_code') || queryParams.get('discount');
    const setDefaults = () => {
      setFinalPrice(finalProduct.finalPrice);
      setSavings(finalProduct.savings);
      setDiscountCode(null);
    };
    if (discountCode) {
      computeDiscountForLinks({
        discountCode,
        finalProduct,
        setFinalPrice,
        setSavings,
        setDiscountCode,
        setDefaults,
      });
    } else {
      setDefaults();
    }
  }, [finalProduct, location]);

  useEffect(() => {
    getBuyerJWTToken() && getProdWishlist(finalProduct?.id);
  }, [finalProduct?.id]);

  const validate = (values: Record<string, string>) => {
    const errors = product?.customFields
      .filter(
        (x) => x.required || [PRODUCT_CUSTOM_FIELD_TYPE.FILE, PRODUCT_CUSTOM_FIELD_TYPE.MULTI_FILE].includes(x.type),
      )
      .filter(
        (x) =>
          ![PRODUCT_CUSTOM_FIELD_TYPE.RADIO, PRODUCT_CUSTOM_FIELD_TYPE.CHECKBOX].includes(x.type) || x.values?.length,
      )
      .reduce((acc, curr) => {
        if (curr.type === PRODUCT_CUSTOM_FIELD_TYPE.CHECKBOX) {
          const atleastOne = Object.values(values[curr.formInputName] || {}).filter((x) => x).length;
          return {
            ...acc,
            [curr.formInputName]: validateRequired(atleastOne ? '  ' : undefined),
          };
        }
        if (curr.type === PRODUCT_CUSTOM_FIELD_TYPE.FILE) {
          let files = values[curr.formInputName + '^^Files'];
          if (files?.length) {
            files = Array.from(files as FileList);
            const fileSize = files[0].size;
            return {
              ...acc,
              [curr.formInputName]: validateFileSize(fileSize, CUSTOM_FIELDS_MAX_FILE_SIZE),
            };
          }
        }
        if (curr.type === PRODUCT_CUSTOM_FIELD_TYPE.MULTI_FILE) {
          const files = values[curr.formInputName + '^^Files'];
          if (files?.length > 0) {
            const result = {
              ...acc,
            };
            const multiFiles = Array.from(files as FileList);
            multiFiles.forEach((item) => {
              result[curr.formInputName] = validateFileSize(item.size, CUSTOM_FIELDS_MAX_FILE_SIZE);
            });
            return result;
          }
        }
        if (curr.required) {
          return {
            ...acc,
            [curr.formInputName]: validateRequired(values[curr.formInputName]),
          };
        }
        return {
          ...acc,
        };
      }, {});
    return errors;
  };

  const form = useForm({
    validate,
    // initialValues,
  });

  const customFieldForm = useForm({
    validate,
  });

  const isPayWhatYouWantFormValid = (): boolean => {
    const parsedEditedPrice = +editedPrice;
    let error = '';
    if (isNaN(parsedEditedPrice)) {
      error = 'Price is not a valid number';
    } else if (parsedEditedPrice < finalPrice) {
      error = 'Price is less than minimum price';
    }
    setEditedPriceError(error);
    return !error;
  };

  const isOptionsFormValid = (): boolean => {
    console.log({ optionsFormRef });
    optionsFormRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
    const errors = validate(form.values);
    return isEmpty(errors);
  };

  const isCustomFieldFormValid = (): boolean => {
    customFieldFormRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
    const errors = validate(customFieldForm.values);
    return isEmpty(errors);
  };

  const addToCart = async ({ isBuyNowFlow, discountLinkPromoCode }: AddToCartParams) => {
    try {
      if (product.hasOptions && !isOptionsFormValid()) {
        return;
      }
      if (product.hasCustomField && !isCustomFieldFormValid()) {
        return;
      }
      if (finalProduct.payWhatYouWant && !isPayWhatYouWantFormValid()) {
        return;
      }
      const finalEditedPrice = !discountCode
        ? +editedPrice
        : finalProduct.finalPrice + Math.max(+editedPrice - finalPrice, 0);

      const otherProps = { finalPrice: finalEditedPrice } as ProductOverridePropsType;
      if (product.hasOptions) {
        const { additionalCharges, formProductOptions } = await getFormProductOptions(product, form.values);
        otherProps.formProductOptions = formProductOptions;
        otherProps.finalPrice = otherProps.finalPrice + additionalCharges;
      }
      if (product.hasCustomField) {
        const { additionalCharges, formCustomFields } = await getFormCustomField(product, customFieldForm.values);
        otherProps.formCustomFields = formCustomFields;
        otherProps.finalPrice = otherProps.finalPrice + additionalCharges;
      }
      addProductToCart({
        product: { ...selectedVariant, ...otherProps },
        quantity,
        disableSuccessToasts: disableSuccessToasts({ product, finalProduct, isBuyNowFlow }),
        isBuyNowFlow,
        discountLinkPromoCode,
        mixpanelProperties: {
          source: 'theme-detail',
        },
      });
      setSelectedOptions(product.optionValues.map(() => 0));
      setCookie(COOKIE_KEYS.IS_CART_ORDER, isBuyNowFlow ? '' : 'true');
    } catch (e) {
      console.log('Some error occured ' + e);
    }
  };

  const addUserWishList = () => {
    if (getBuyerJWTToken()) {
      addToWishlist(finalProduct?.id);
    } else {
      history.push(`/account/login?redirect_url=${redirectUrl}`);
    }
  };

  const removeUserWishList = () => {
    if (getBuyerJWTToken()) {
      removeFromWishlist(finalProduct?.id);
    } else {
      history.push(`/account/login?redirect_url=${redirectUrl}`);
    }
  };

  const onWishListClickHandler = () => {
    isProductInWishList ? removeUserWishList() : addUserWishList();
  };

  return (
    <>
      {render({
        finalProduct,
        showTicketsLeft,
        eventStartDate,
        eventStartTime,
        eventEndDate,
        eventEndTime,
        editedPrice,
        setEditedPrice,
        editedPriceError,
        optionsFormRef,
        customFieldFormRef,
        form,
        customFieldForm,
        addToCart,
        variant,
        minQuantityGreaterThanOne,
        isBuyNowButtonDisabled,
        isAddToCartButtonDisabled,
        showAddToCartButton,
        showAddToCartButtonAlt,
        showStepper,
        cart,
        storeInfo,
        savings,
        finalPrice,
        discountCode,
        addUserWishList,
        removeUserWishList,
        onWishListClickHandler,
        wishlist,
      })}
    </>
  );
};

export default connect(({ storeInfo, cart, wishlist }: RootState) => ({ storeInfo, cart, wishlist }), {
  addProductToCart,
  addToWishlist,
  removeFromWishlist,
  getProdWishlist,
})(ProductAddToCartHOC);

const getBuyNowItem = ({
  selectedVariant,
  product,
}: {
  selectedVariant: Variant;
  product: Product;
}): CartProductType | undefined => {
  let buyNowItem;
  if (product.hasVariants && selectedVariant) {
    const stockLeft = selectedVariant.stock - selectedVariant.minQuantity;
    if (stockLeft < 0) {
      // Do nothing
    } else {
      buyNowItem = { ...selectedVariant, cartQuantity: selectedVariant.minQuantity };
    }
  } else if (!product.hasVariants) {
    const stockLeft = product.stock - product.minQuantity;
    if (stockLeft < 0) {
      // Do nothing
    } else {
      buyNowItem = { ...product, cartQuantity: product.minQuantity };
    }
  }
  // TODO: Maybe hardcode Digital Products Quantity as 1.
  return buyNowItem;
};

import Product from '@common/models/Product';
import { STORE_TYPE } from '@common/models/StoreInfo';
import { RouteMap } from '@common/RouteMap';
import { RootState, store } from '@common/store';
import { addPromoCodeToCartSuccess, CartSliceType } from '@common/store/cart/slice';
import { StoreInfoSliceType } from '@common/store/storeInfo/slice';
import {
  addToWishlist,
  fetchWishListRequest,
  getProdWishlist,
  // getUserWishlistQueryParamsInitialState,
  removeFromWishlist,
  WishListSliceType,
} from '@common/store/wishList/slice';
import { performCheckout } from '@common/utils/checkout';
import { computeRedirectUrl } from '@common/utils/computation';
import { computeCartProperties, ComputedCartProperties } from '@common/utils/index';
import { getBuyerJWTToken } from '@common/utils/token';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

interface CartCheckoutHOCRenderArgs extends ComputedCartProperties {
  isStoreInfoFetching: boolean;
  isPaymentsDisabled: boolean;
  storeType: string;
  isCheckoutDisabled: boolean;
  checkoutHandler: () => void;
  hidePromoCodeSection: boolean;
  wishlist: WishListSliceType;
  addUserWishList: (product: Product) => void;
  removeUserWishList: (product: Product) => void;
  getProdWishlist: typeof getProdWishlist;
}

interface CartCheckoutHOCProps {
  cart: CartSliceType;
  storeInfo: StoreInfoSliceType;
  render: (args: CartCheckoutHOCRenderArgs) => React.ReactNode;
  getProdWishlist: typeof getProdWishlist;
  wishlist: WishListSliceType;
  addToWishlist: typeof addToWishlist;
  removeFromWishlist: typeof removeFromWishlist;
  fetchWishListRequest: typeof fetchWishListRequest;
}

const CartCheckoutHOC: React.FC<CartCheckoutHOCProps> = ({
  render,
  cart,
  storeInfo,
  addToWishlist,
  wishlist,
  getProdWishlist,
  removeFromWishlist,
  fetchWishListRequest,
}) => {
  const computedCartValues = computeCartProperties(cart);
  const { products, promoCode, totalProducts, totalAmount, cartHasResellerProduct } = computedCartValues;
  const isStoreInfoFetching = storeInfo.isFetching;
  const isPaymentsDisabled = !storeInfo.storeInfo?.paymentsAllowed || !storeInfo.storeInfo?.isCodEnabled;
  const storeType = storeInfo.storeInfo?.storeType;

  const hidePromoCodeSection = cartHasResellerProduct;
  const isCheckoutDisabled =
    isStoreInfoFetching ||
    // (isPaymentsDisabled && storeType === STORE_TYPE.SHOPPING_STORE) ||
    totalProducts === 0 ||
    storeType === STORE_TYPE.LANDING;

  // Wishlist
  const history = useHistory();
  const redirectUrl = computeRedirectUrl();

  useEffect(() => {
    if (getBuyerJWTToken()) {
      fetchWishListRequest({
        queryParams: {
          limit: 50,
          offset: 0,
          page: 1,
        },
      });
    }
  }, []);

  const addUserWishList = async (product) => {
    if (getBuyerJWTToken()) {
      addToWishlist(product?.id);
    } else {
      history.push(`${RouteMap.ACCOUNT_LOGIN}?redirect_url=${redirectUrl}`);
    }
  };

  const removeUserWishList = async (product) => {
    if (getBuyerJWTToken()) {
      removeFromWishlist(product?.id);
    } else {
      history.push(`${RouteMap.ACCOUNT_LOGIN}?redirect_url=${redirectUrl}`);
    }
  };

  useEffect(() => {
    if (hidePromoCodeSection) {
      store.dispatch(addPromoCodeToCartSuccess(null));
    }
  }, [hidePromoCodeSection]);

  const checkoutHandler = () =>
    performCheckout({
      promoCode: promoCode?.code,
      totalItems: totalProducts,
      totalPrice: totalAmount,
      items: products,
    });

  return (
    <>
      {render({
        ...computedCartValues,
        isStoreInfoFetching,
        isPaymentsDisabled,
        storeType,
        isCheckoutDisabled,
        checkoutHandler,
        hidePromoCodeSection,
        addUserWishList,
        removeUserWishList,
        wishlist,
        getProdWishlist,
      })}
    </>
  );
};

export default connect(
  ({ storeInfo, cart, wishlist }: RootState) => ({
    storeInfo,
    cart,
    wishlist,
  }),
  {
    addToWishlist,
    fetchWishListRequest,
    removeFromWishlist,
    getProdWishlist,
  },
)(CartCheckoutHOC);

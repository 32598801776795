import ProductBundle from '@common/components/ProductBundle';
import ProductSEOTags from '@common/components/ProductSEOTags';
// import StoreSpecials from '@common/components/StoreSpecials';
// import { ReactComponent as BreadcrumbRightArrow } from '@common/icons/BreadcrumbRightArrow.svg';
// import { ReactComponent as NextArrow } from '@common/icons/NextArrow.svg';
import { RootState } from '@common/store';
import {
  addBundleProductToCartSuccess,
  addProductToCart,
  CartSliceType,
  subtractProductFromCart,
} from '@common/store/cart/slice';
import {
  fetchProductBundleRequest,
  fetchProductDetailsRequest,
  fetchProductReviewsRequest,
  ProductDetailsSliceType,
} from '@common/store/productDetails/slice';
import {
  fetchProductsRequest,
  getProductQueryParamsInitialState,
  ProductsSliceType,
} from '@common/store/products/slice';
import { RecentlyViewedSliceType } from '@common/store/recentlyViewed/slice';
import { StoreInfoSliceType } from '@common/store/storeInfo/slice';
import { addToast } from '@common/store/toasts/slice';
import { smoothScroll } from '@common/utils';
import { PRODUCTS_KEY } from '@common/utils/constants';
import PageLayout from '@components/PageLayout';
import Product from '@components/Product';
import Rating from '@components/Rating';
import RecentlyViewed from '@components/RecentlyViewed';
// import Share from '@components/Share';
import Error from '@lib/Error';
import InjectScript from '@lib/InjectScript';
// import Link from '@lib/Link';
import Loading from '@lib/Loading';
import MDPInjectScript from '@lib/MDPInjectScript';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { MDPPageType } from '../../../../common/models/MDPAppScript';
import { ERROR_TEXT } from '../../../../common/utils/errorContent';

interface MatchParams {
  productId: string;
  productSlug: string;
}

interface ProductDetailProps extends RouteComponentProps<MatchParams> {
  cart: CartSliceType;
  productDetails: ProductDetailsSliceType;
  products: Record<string, ProductsSliceType>;
  recentlyViewed: RecentlyViewedSliceType;
  addToast: typeof addToast;
  addProductToCart: typeof addProductToCart;
  subtractProductFromCart: typeof subtractProductFromCart;
  fetchProductDetailsRequest: typeof fetchProductDetailsRequest;
  fetchProductsRequest: typeof fetchProductsRequest;
  fetchProductReviewsRequest: typeof fetchProductReviewsRequest;
  storeInfo: StoreInfoSliceType;
  fetchProductBundleRequest: typeof fetchProductBundleRequest;
  productBundle: typeof ProductDetailsSliceType;
  addBundleProductToCartSuccess: typeof addBundleProductToCartSuccess;
}

class ProductDetails extends React.Component<ProductDetailProps> {
  constructor(props) {
    super(props);
    const productId = +this.props.match.params.productId;
    const payload = {
      productId: productId,
      location: 'product',
    };
    if (this.props.productDetails.productId !== productId) {
      this.props.fetchProductDetailsRequest(productId);
      this.props.fetchProductBundleRequest(payload);
      this.props.fetchProductsRequest({
        storeKey: PRODUCTS_KEY.ALL,
        queryParams: {
          ...getProductQueryParamsInitialState(),
          category: undefined,
        },
      });
    }
    smoothScroll(0, 0);
  }
  componentDidUpdate(prevProps) {
    const productId = +this.props.match.params.productId;
    const previousProductId = +prevProps.match.params.productId;
    const payload = {
      productId: productId,
      location: 'product',
    };
    if (previousProductId !== productId) {
      this.props.fetchProductDetailsRequest(productId);
      this.props.fetchProductReviewsRequest(productId);
      this.props.fetchProductBundleRequest(payload);
      this.props.fetchProductsRequest({
        storeKey: PRODUCTS_KEY.ALL,
        queryParams: {
          ...getProductQueryParamsInitialState(),
          category: undefined,
        },
      });
      smoothScroll(0, 0);
    }
  }
  componentDidMount(): void {
    const productId = +this.props.match.params.productId;
    this.props.fetchProductReviewsRequest(productId);
  }
  render() {
    const {
      products,
      productDetails,
      recentlyViewed,
      addToast,
      addProductToCart,
      cart,
      addBundleProductToCartSuccess,
      storeInfo,
    } = this.props;
    const productId = +this.props.match.params.productId;

    const product = productDetails.productDetails;
    const cartProductQuantity = cart.quantityByProductId[product?.id] || 0;
    const totalProductsCount = products[PRODUCTS_KEY.ALL]?.list?.results?.length;
    const features = this.props?.features?.features || {};
    const seoTags = <ProductSEOTags productDetails={productDetails} />;
    return (
      <PageLayout filterBarType="product" showBranding={false}>
        <InjectScript others="product" product={productId} />
        <MDPInjectScript pageType={MDPPageType.PRODUCT_PAGE} />
        <MDPInjectScript pageType={MDPPageType.STORE_PAGE} />
        {!productDetails.isFetched ? (
          <Loading />
        ) : productDetails.isFetched && (productDetails.error || !product?.id) ? (
          <Error message={ERROR_TEXT.PRODUCT} />
        ) : (
          <div className="mt-14">
            {seoTags}
            <div className="container px-0 md:px-42 mx-auto flex flex-wrap md:flex-no-wrap mb-20">
              {productDetails.isFetched ? (
                <Product
                  subtractProductFromCart={this.props.subtractProductFromCart}
                  product={product}
                  addProductToCart={addProductToCart}
                  addToast={addToast}
                  cartProductQuantity={cartProductQuantity}
                  isZoomEnabled={false}
                  storeType={this.props.storeInfo.storeInfo?.storeType}
                  storeInfo={this.props.storeInfo.storeInfo}
                  isPaymentsDisabled={
                    !this.props.storeInfo.storeInfo?.paymentsAllowed || !this.props.storeInfo.storeInfo?.isCodEnabled
                  }
                  isStoreInfoFetching={this.props.storeInfo.isFetching}
                  wishListFeature={features?.wishList}
                  productVideoFeature={features?.productVideo}
                />
              ) : null}
              <div className="w-full hidden md:flex p-16 md:w-1/3 max-h-170 h-fit rounded-md bg-white mt-20 md:mt-0 md:ml-12 flex-col">
                <span className="text-18 font-bold text-prodTitleTxt mb-14 uppercase">
                  {storeInfo?.storeInfo?.storename}
                </span>
                <span className="text-12 text-subtext uppercase">{totalProductsCount} products on store</span>
                <a href="/">
                  <button className="rounded-md mt-20 uppercase bg-white w-full h-36 font-bold text-12 flex justify-center items-center border border-nav text-nav">
                    VIEW STORE
                  </button>
                </a>
              </div>
            </div>
            {features.productReview && (
              <div id="product-reviews" className="container px-0 md:px-42 mx-auto md:mb-40">
                <Rating
                  isNextReviewsLoading={productDetails.isNextReviewsLoading}
                  isNextReviewsLoadingFailed={productDetails.isNextReviewsLoadingFailed}
                  nextReviewsUrl={productDetails?.nextReviewsUrl}
                  reviewsCount={productDetails?.reviewsCount}
                  reviews={productDetails.reviews}
                  productId={product?.id}
                />
              </div>
            )}
            <div className="w-full flex md:hidden p-16 md:w-1/3 max-h-170 h-fit rounded-md bg-white mb-20 md:mt-0 md:ml-12 flex-col">
              <span className="text-18 font-bold text-prodTitleTxt mb-14 uppercase">
                {storeInfo?.storeInfo?.storename}
              </span>
              <span className="text-12 text-subtext uppercase">{totalProductsCount} products on store</span>
              <a href="/">
                <button className="rounded-md mt-20 uppercase bg-white w-full h-36 font-bold text-12 flex justify-center items-center border border-nav text-nav">
                  VIEW STORE
                </button>
              </a>
            </div>
            <div className="container px-0 md:px-42 mx-auto hidden">
              <RecentlyViewed products={recentlyViewed.products} ignoreProducts={new Set([product?.id])} />
            </div>
            {productDetails.bundle && (
              <div className="md:w-1140 w-full mx-auto">
                <ProductBundle
                  productsBundle={productDetails.bundle}
                  productId={product?.id}
                  addToBundleProductInCart={addBundleProductToCartSuccess}
                />
              </div>
            )}
          </div>
        )}
      </PageLayout>
    );
  }
}

export default connect(
  ({ cart, productDetails, products, recentlyViewed, features, storeInfo }: RootState) => ({
    storeInfo,
    cart,
    products,
    productDetails,
    recentlyViewed,
    features,
  }),
  {
    fetchProductDetailsRequest,
    fetchProductReviewsRequest,
    fetchProductsRequest,
    addToast,
    addProductToCart,
    subtractProductFromCart,
    fetchProductBundleRequest,
    addBundleProductToCartSuccess,
  },
)(ProductDetails);
